import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Select allows a user to make a selection from a list of options in a form field. By default, it will show the placeholder or the first option in the list.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/selectfield/guideline-1.svg",
      "alt": "anatomy select field"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Container :`}</strong>{` Selection control (input) that can be selected or unselected, and pushed into different states`}</li>
      <li parentName="ol"><strong parentName="li">{`Icon Left :`}</strong>{` Optionally icon for represent context of selection`}</li>
      <li parentName="ol"><strong parentName="li">{`Menu Container :`}</strong>{` Dropdown or popover to show list of item option when user click the select field`}</li>
      <li parentName="ol"><strong parentName="li">{`Input Container :`}</strong>{` Placeholder text like “Select an option” is typically displayed in the Select field. After the user makes a selection, the placeholder text is replaced with the user’s selection and for multiple selection the placeholder text is replaced with chips`}</li>
      <li parentName="ol"><strong parentName="li">{`Icon Right :`}</strong>{` Caret icon positioned to the right of the container visually distinguishes this as a Select input.`}</li>
      <li parentName="ol"><strong parentName="li">{`Menu Item :`}</strong>{` A list of options in the menu container that the user can select by clicking on the item`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Selects work well to present many different options in a compact unit. This way users don’t have to scroll through tens of options to find what they’re looking for. When you have only a few options so users aren’t overwhelmed by them, it can help to present them all at once. Don’t confuse a select box with a dropdown menu. Select boxes are for setting options and work best in forms. Dropdowns are for presenting actions and most appropriate in a header.`}</p>
    <p><strong parentName="p">{`Single select :`}</strong>{` lists allow users to select a single, mutually exclusive option. When the user selects an option from the list, the selection appears in the toggle.`}</p>
    <p><strong parentName="p">{`Multiple select :`}</strong>{` lists allow users to select one or more options from a list, with the selected options marked with a blue check. It differs from the checkbox select in that each option selected by the user appears in the top display area as chips. Once the number of selections reaches a certain number, the regular chips will be replaced by a gray chip indicating how many more items were selected. The default number of selections is 3, but you may change this number based on your use case.`}</p>
    <p><strong parentName="p">{`Common places buttons appear :`}</strong>{` Forms, filter, etc`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>
        Use Select as a form element where users are only allowed to select one item from a list of more than 7
        predefined options.
      </li>
      <li>
        Typically, Selects work best when the list is between 7 to 15 items to prevent overwhelming the user with too
        many options.
      </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>
        For a list between 2 to 7 predefined options, consider using Radio to select one option or Checkboxes to select
        multiple options. Radio and Checkbox groups display all options upfront and do not require the user to interact
        with the input to view the list of options.
      </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`Keep menu items short and concise. Long menu items that cause text to wrap to multiple lines are discouraged. If text wrapping becomes a frequent concern, consider revising the text or use alternative UI patterns that will give your content more space, When possible, the field button width should be wide enough so that any chosen menu items can be displayed in full and for a single form, mark only the required fields or only the optional fields, depending on whichever is less frequent in the entire form. If most of the pickers are optional, only the required fields should be given an asterisk or have labels appended with “(required)”. If most of the pickers are required, only the optional fields should be appended with “(optional)”. An asterisk should never be used to note that a picker is optional.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do" caption="Sort menu item with appropriate width" aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/selectfield/guideline-2.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
  <DoDont type="dont" captionTitle="Don't" caption="Long menu item with limited width " aspectRatio="16:8" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/selectfield/guideline-3.svg",
          "alt": "Alt text"
        }}></img>
  </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating select :`}</p>
    <ul>
      <li parentName="ul">{`Keep option items short and concise. Long menu items that cause text to wrap to multiple lines are discouraged.`}</li>
      <li parentName="ul">{`When possible, the field button width should be wide enough so that any chosen menu items can be displayed in full.`}</li>
      <li parentName="ul">{`Field labels, placeholder text, and menu items should be in sentence case.`}</li>
      <li parentName="ul">{`Used description in the help text is can communicate what to select or how to select an option.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      